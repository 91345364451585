@font-face {
  font-family: "Archivo Narrow";
  font-weight: normal;
  font-style: normal;
  src: url(/main/assets/fonts/core/archivo-narrow/webfonts/ArchivoNarrow-Regular.otf?777ac7372edbbab5956123eebf11935e);
}

@font-face {
  font-family: "Archivo Narrow";
  font-weight: bold;
  font-style: normal;
  src: url(/main/assets/fonts/core/archivo-narrow/webfonts/ArchivoNarrow-Bold.otf?1e72899ef79ad3320652a91f92cfa509);
}

@font-face {
  font-family: "Bebas Neue";
  font-weight: normal;
  font-style: normal;
  src: url(/main/assets/fonts/core/bebas-neue/webfonts/BebasNeue-Regular.ttf?b2b293064f557c41411aac04d6f6710d);
}

@font-face {
  font-family: "Mikado";
  font-weight: bold;
  font-style: normal;
  src: url(/main/assets/fonts/core/mikado/webfonts/mikado-bold.ttf?da897c5bafd9743c93f00b0ae43fbf74);
}

@font-face {
  font-family: "Mikado Black";
  font-weight: 900;
  font-style: normal;
  src: url(/main/assets/fonts/core/mikado/webfonts/mikado-black.ttf?45915a91e9e3d2e396daf6801b8cd0bc);
}

@font-face {
  font-family: "MikadoPhaserBlack";
  font-weight: normal;
  font-style: normal;
  src: url(/main/assets/fonts/core/mikado/webfonts/mikado-black.ttf?45915a91e9e3d2e396daf6801b8cd0bc);
}

@font-face {
  font-family: "MikadoPhaser";
  font-weight: bold;
  font-style: normal;
  src: url(/main/assets/fonts/core/mikado/webfonts/mikado-bold.ttf?da897c5bafd9743c93f00b0ae43fbf74);
}

@font-face {
  font-family: "swiper-icons";
  font-weight: 600;
  font-style: normal;
  src: url(/main/assets/fonts/core/swiper-icons/webfonts/swiper-icons.ttf?649fd2f58b45a1415792f4e8a0814223);
}
